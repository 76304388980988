import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/porcelain-teeth.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'
import BtnOutlined from 'components/button/outlined-button'
import TelLinkSimple from 'components/tel-link-simple'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const ImplantDentistryPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const pd_cta_background = data.pd_cta_background?.childImageSharp?.fluid
  const service_implant_1 = data.service_implant_1?.childImageSharp?.fluid
  const service_implant_2 = data.service_implant_2?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Implant Dentistry - The Dentists At Gateway Crossing</title>
      <meta name="description" content="Dental Implants are the most natural look and feel of any other type of smile restoration. Browse the page below to learn more. It's easy to schedule your" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Implant Dentistry - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Dental Implants are the most natural look and feel of any other type of smile restoration. Browse the page below to learn more. It's easy to schedule your" />
      <meta property="og:url" content="https://thedentistsatgc.com/services/implant-dentistry/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="2000" />
      <meta property="og:image:height" content="1380" />
      <meta property="og:image:alt" content="porcelain-teeth" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Implant Dentistry - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Dental Implants are the most natural look and feel of any other type of smile restoration. Browse the page below to learn more. It's easy to schedule your" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
                IMPLANT DENTAL SERVICES MCCORDSVILLE
              </p>
              <h1 className="text-light">
                Implant Dentistry
              </h1>
            </div>
            <div className="col-lg-12 text-center">
              <p className="hero-text text-light">We offer many types of preventative dentistry services to help you keep that smile looking bright and healthy. Browse the page below to learn more. It’s easy to schedule your appointment with us.</p>
              <p className="hero-text text-light">Just click “schedule now” as soon as you’re ready.</p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#pd-section-3"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="pd-section-1" className="page-section text-center">
          <Container>
            <div className="col-lg-12">
              <p className ="section-1-text">DENTAL IMPLANT PROCEDURES</p>
              <h2 className ="section-2-header">Rebuilding Smiles With Dental Implants</h2>
              <p>Having a full set of teeth can make your first impression a great one. Dental implants don’t just look good, they also function just like your original teeth. Your body was designed to have a full set of teeth and we want you to be able to experience that not only for the aesthetics buy for the health benefits too. Dr. Vogt is committed to providing his patients with the healthiest smiles. Read the benefits of dental implants below.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Better Pay & Benefits</h3>
                <p>Studies have shown that straight teeth and beautiful smiles can impact things like getting a promotion or securing a new job.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Great First Impressions</h3>
                <p>People usually remember a great smile longe after they meet for the first time. If your teeth keep you from smiling, you may come across as rude or unfriendly.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">Boosts Your Self Esteem</h3>
                <p>Feeling confident can change every interaction in your life. Imagine being able to to talk, laugh, smile, and let people see your teeth with out feeling embarrassed.</p>
              </div>
            </div>
          </Container>
          <Container>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bolt" aria-hidden="true"></i>
                <h3 className ="section-1-header">Improves Overall Dental Health</h3>
                <p>Once you invest the time and energy into a great smile you're more likely to take make sure it stays that way. Brushing, flossing, whitening, suddenly become more important.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-diamond" aria-hidden="true"></i>
                <h3 className ="section-1-header">Smile More & Live Longer</h3>
                <p>Researchers have found that you can live a longer healthier life just by smiling more often. When your smile is beautiful, you're going to show it off.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="columns">
                <i className="fa fa-bookmark-o" aria-hidden="true"></i>
                <h3 className ="section-1-header">High Trust & Productivity</h3>
                <p>Smiling increases our perception of being trustworthy. At the same time it elevates our mood and energy levels. When you're happy, you're productive!</p>
              </div>
            </div>
          </Container>
           <Container>
            <div className="col-lg-6">
              <Img fluid={service_implant_1} alt="Dental Crown" />
            </div>
            <div className="col-lg-6">
              <Img fluid={service_implant_2} alt="Portrait of Happy Smiling Man" />
            </div>
          </Container>
        </section>
        <BackgroundImage
          Tag="section"
          className="pdsection2-bg"
          fluid={pd_cta_background}
          backgroundColor={`#e9f4f8`}
          alt="The Dentists at Gateway Crossing"
        >
          <div className ="col-lg-12" id ="color-overlay">
          </div>
          <section id="pdsection-2" className="page-section">
            <Container>
              <div className="col-lg-6">
                <p className="hero-text">
                COMPLETE YOUR SMILE!
                </p>
                <h3 className="text-light">
                Don't miss another chance to live life with a healthy smile
                </h3>
                <p className="hero-text text-light">
                You were meant to have a full set of teeth! Missing teeth can cause bone loss, especially multiple missing teeth. Don’t put your health and your smile on the back burner and regret it later. Call TODAY!
                </p>
                <BtnOutlined url="tel:3176439434" class="btn-rounded" label="(317) 643-9434" />
              </div>
            </Container>
            </section>
          </BackgroundImage>
          <section id ="pd-section-3" className="page-section">
          <Container>
            <div className="col-lg-12 text-center">
              <p className ="section-1-text">COSMETIC DENTAL DETAILS</p>
              <h2 className ="section-3-header">What Are Dental Implants?</h2>
              <p>Although dental implants are artificially produced replicas of the teeth you once had, they are the closest thing you will get to the real thing. </p>
              <p>Dental implants are just one part of a three piece system for replacing your teeth. The first part is basically a screw that is implanted into your bone. Your bone heals around this base after several months and allows Dr. Vogt to attach a connector.</p>
              <p>The second piece that acts as a connector is called an abutment. The abutment is placed on top of the implant screw. This piece functions to hold and support you custom made crowns.</p>
              <p>The last piece is called the crown. This is the part that looks just like natural teeth. The crown is attached to the abutment and looks feels and acts just like a real tooth. Sometimes there are multiple crowns attached to one abutment in cases where multiple teeth are being replaced. </p>
              <p>Dentists have been successfully using this surgical technique for over 30 years. Dr. Vogt has been highly trained on the proper procedures to design build and implant your new teeth so you can get back to a health happy smile.</p>
            </div>
          </Container>
          <Container>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Dental Implants Right For You?</h3>
                <p>You may have one or more missing teeth that are negatively affecting your health and life. Sometimes it becomes difficult to eat, drink, or talk. If your missing teeth are visible it can be embarrassing and you may choose not to smile when you really want to. It’s ok. You’re not alone.</p>
                <strong><p>Did you know that missing teeth can lead to bone loss in the mouth? Yikes!</p></strong>
                <p>Millions of people have lost their teeth either from disease or an accident. The important thing is that there is a way to fix it and stop the potential loss of health associated with missing teeth.</p>
                <strong><p>Do you remember what it was like to have a full set of teeth and smile you were happy to show off? </p></strong>
                <p>Imagine yourself having a full set of teeth. You can smile when you want. Food suddenly isn’t an obstacle and worrying about that space in your mouth is a thing of the past. You’re back to normal again and can live without the worries that you’re experiencing now. </p>
                <p>If you’re on the edge about dental implants, don’t worry. Dr. Vogt is a very capable dentist and he can guide you every step of the way to getting your teeth, gums, smile, and your whole mouth back to being health!</p>
                <p>The only thing you have to do is contact us for a free consultation. We will make every step of the process easier than you can imagine.</p>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Our Implant Dentistry Services List</h3>
                <p>Our implant dentistry services include:</p>
                <ul>
                  <li>Single Tooth Replacement</li>
                  <li>Fixed Bridges (non-removable)</li>
                  <li>Removable Implant Bridges or Partials</li>
                  <li>Fixed Implant Dentures – all on 4 – hybrid (non-removable)</li>
                  <li>Removable Implant Dentures or over-dentures</li>
                  <li>Full Mouth Treatment</li>
                  <li>Mini Implants</li>
                  <li>Bone Grafting</li>
                  <li>Same Day Tooth Replacement</li>
                  <li>Intravenous (IV) Sedation and Oral Sedation</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-12 text-left">
              <div className="columns">
                <h3 className ="section-3-header">Are Implant Dental Procedures Covered By My Insurance?</h3>
                <p>Every patient’s situation is different. We would love to discuss payment options with you. Please call our office at <TelLinkSimple />.</p>
              </div>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default ImplantDentistryPage

export const query = graphql`
  query ImplantDentistryPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    pd_cta_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_implant_1: file(name: { eq: "implant_1" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    service_implant_2: file(name: { eq: "implant_2" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
        
      }
    }
  }
`
